.cover-img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  overflow: hidden;
}

li.dots {
  color: var(--color-white);
  font-family: Poppins;
}

ul .dots {
  list-style: none; /* Remove default bullets */
}

ul li.dots::before {
  content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: var(--color-green); /* Change the color */
  font-weight: bolder; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
}

.divider {
  border-bottom: 2px solid;
  border-bottom-color: var(--color-white);
  width: 90%;
}

li.dots {
  font-size: 1.5em;
}

ul li.dots::before {
  font-size: 1em;
  width: 1em;
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}

.aboutme-round-corners {
  border-radius: 10%;
  overflow: hidden;
}

/* Small Screen */
@media screen and (max-width: 39.9375em) {
  ul li.dots::before {
    font-size: 0.75em;
    width: 0.75em;
    margin-left: -0.75em;
  }

  .aboutme-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 5%;
    padding-top: 3%;
    padding-right: 5%;
  }

  .cover-img {
    object-fit: scale-down;
  }

  .aboutme-img-container {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 85%;
    border-radius: 10%;
    overflow: hidden;
    flex: 0.7;
    z-index: 1;
  }

  .aboutme-title {
    font-size: 1.5em;
  }

  .aboutme-text {
    font-size: 1.2em;
  }
}

/* Medium Screen */
@media screen and (min-width: 40em) and (max-width: 74.9375em) {
  .aboutme-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 3%;
    padding-top: 3%;
    padding-right: 3%;
  }

  .cover-img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
  }

  .aboutme-text-container {
    margin-left: 3%;
    flex: 2;
  }

  .aboutme-img-container {
    z-index: 1;
    width: 85%;
    flex: 3;
    overflow: hidden;
  }

  .aboutme-tech-title {
    display: none;
  }
}

/* Large Screen */
@media screen and (min-width: 75em) {
  .aboutme-layout {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 3%;
    padding-top: 3%;
    padding-right: 3%;
  }

  .cover-img {
    width: 100%;
    height: 100%;
    border-radius: 10%;
    object-fit: cover;
    overflow: hidden;
  }

  .aboutme-text-container {
    display: flex;
    height: inherit;
    align-items: center;
    justify-content: center;
    margin-left: 3%;
    flex: 3;
  }

  .aboutme-img-container {
    flex: 2;
    z-index: 1;
    border-radius: 10%;
    height: 70%;
  }

  img {
    opacity: 60%;
  }

  img:hover {
    opacity: 100%;
  }

  .aboutme-tech-title {
    display: none;
  }

  .divider {
    margin-top: 5%;
  }
}

@media screen and (min-width: 75em) and (min-height: 60em) {
  li.dots {
    font-size: 2em;
  }
  ul li.dots::before {
    font-size: 1em;
    width: 1em;
    margin-left: -1em; /* Also needed for space (tweak if needed) */
  }
}
