:root {
  --color-white: whitesmoke;
  --color-green: yellowgreen;
}

body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.heading-white {
  color: var(--color-white);
  font-family: Poppins;
  font-weight: normal;
}

.text-white {
  color: var(--color-white);
  font-family: Poppins;
  font-weight: normal;
}

.text-poppins {
  font-family: Poppins;
}

.text-bold {
  font-weight: bold;
}

.text-italic {
  font-style: italic;
}

.w-80 {
  width: 80%;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.heading-white {
  font-size: 2em;
}
.text-white {
  font-size: 1.5em;
}

.background-icon {
  position: absolute;
  opacity: 0.4;
}

.mb-0 {
  margin-bottom: 0;
}

.mt-0 {
  margin-top: 0;
}

.ml-0 {
  margin-left: 0;
}

.mr-0 {
  margin-right: 0;
}

.abs-t-0 {
  top: 0;
}

.abs-l-0 {
  left: 0;
}

.abs-r-0 {
  right: 0;
}

.abs-b-0 {
  bottom: 0;
}

.d-flex {
  display: flex;
}

.d-column {
  flex-direction: column;
}

.d-row {
  flex-direction: row;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}
.rotate-180 {
  transform: rotate(180deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

/* Large and up */
@media screen and (min-width: 75em) and (min-height: 60em) {
  .heading-white {
    font-size: 3em;
  }
  .text-white {
    font-size: 2em;
  }
}

@font-face {
  font-family: "Poppins";
  src: local("PoppinsRegular"),
    url("../fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: local("PoppinsBold"), url("../fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: local("PoppinsItalic"),
    url("../fonts/Poppins-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
