.recentwork-date-text {
  font-family: Poppins;
  font-weight: normal;
}

/* Small Screen */
@media screen and (max-width: 1169px) {
  .recentwork-date-text {
    color: black;
  }
  .recentwork-heading {
    display: none;
  }
}

/* Medium and up */
@media screen and (min-width: 1170px) {
  .recentwork-date-text {
    color: var(--color-white);
  }
}
