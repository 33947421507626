.icon:hover {
  opacity: 50%;
}

.welcome-name:hover::after {
  width: 0%;
}

/* Small Screen */
@media screen and (max-width: 39.9375em) {
  .welcome-name {
    font-size: 3em;
    position: relative;
    color: var(--color-green);
  }

  .welcome-container {
    display: flex;
    height: inherit;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .welcome-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 2;
  }
  .welcome-icons-container {
    flex: 0;
    z-index: 1;
    margin-bottom: 1em;
    flex-direction: row;
  }

  .welcome-middle-icon {
    margin-left: 1.5em;
    margin-right: 1.5em;
  }
}

/* Medium Screen */
@media screen and (min-width: 40em) and (max-width: 74.9375em) {
  .welcome-name {
    font-size: 4em;
    position: relative;
    color: var(--color-green);
  }

  .welcome-name::after {
    content: "Dennis Ostertag";
    position: absolute;
    top: 0;
    left: 0;
    color: var(--color-white);
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    -webkit-transition: width 0.5s ease;
    transition: width 0.5s ease;
  }

  .welcome-container {
    display: flex;
    height: inherit;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .welcome-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 5;
  }

  .welcome-icons-container {
    flex: 0;
    z-index: 1;
    margin-right: 1.5em;
    flex-direction: column;
  }

  .welcome-middle-icon {
    margin-bottom: 1.5em;
    margin-top: 1.5em;
  }
}

/* Large and up */
@media screen and (min-width: 75em) {
  .welcome-name {
    font-size: 5em;
    position: relative;
    color: var(--color-green);
  }

  .welcome-name::after {
    content: "Dennis Ostertag";
    position: absolute;
    top: 0;
    left: 0;
    color: var(--color-white);
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    -webkit-transition: width 0.5s ease;
    transition: width 0.5s ease;
  }

  .welcome-container {
    display: flex;
    height: inherit;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .welcome-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 5;
  }

  .welcome-icons-container {
    flex: 0;
    z-index: 1;
    margin-right: 1.5em;
    flex-direction: column;
  }

  .welcome-middle-icon {
    margin-bottom: 1.5em;
    margin-top: 1.5em;
  }
}
